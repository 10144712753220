"use client"

import { showDesktop, showMobile } from "@/app/_assets/styles/media.css"
import { PhotoCardBasicCarousel } from "@/app/_components/Card/PhotoCard/PhotoCardBasicCarousel"
import { PhotoCardBasicThumbnail } from "@/app/_components/Card/PhotoCard/PhotoCardBasicThumbnail"
import { LineClamp } from "@/app/_components/LineClamp"
import { UserIconName } from "@/app/_components/UserIconName"
import clsx from "clsx"
import * as styles from "./PhotoCardBasic.css"

// TODO: 型の共通化を考える(一旦ここに定義する)
export interface PhotoCardBasicPhoto {
  id: number
  hashId: string
  imageUrl: string
  largeImageUrl: string
  commentText?: string
  photoImages?: {
    photoImageId: number
    imageUrl: string
    largeImageUrl: string
  }[]

  // TODO: 以下のような感じ？
  // tags?: {
  //   id: number
  //   name: string
  //   displayName: string
  // }[]
}

export interface PhotoCardBasicUser {
  id: number
  name: string
  iconUrl: string
  layout?: string
  style?: string
}

interface Props {
  size?: "medium" | "large"
  photo: PhotoCardBasicPhoto
  user?: PhotoCardBasicUser
  // items: any[] TODO
  thumbnailView?: boolean
  index?: number
  className?: string
  gtmClass?: string
}

export function PhotoCardBasic({
  size = "medium",
  photo,
  user,
  thumbnailView = false,
  index,
  className,
  gtmClass
}: Props) {
  // Before/Afterの切り替え機能があるバージョン
  if (photo.photoImages !== undefined && photo.photoImages.length > 0) {
    return (
      <PhotoCardBasicCarousel
        photo={photo}
        user={user}
        thumbnailView={thumbnailView}
        size={size}
        index={index ?? 0}
        className={className}
        gtmClass={gtmClass}
      />
    )
  }

  return (
    <a
      href={`/photo/${photo.hashId}`}
      className={clsx(styles.cardContainer, className, gtmClass)}
    >
      <PhotoCardBasicThumbnail
        imageUrl={photo.imageUrl}
        largeImageUrl={photo.largeImageUrl}
        user={user}
        thumbnailView={thumbnailView}
        size={size}
      />

      {(photo.commentText || user !== undefined) && (
        <div
          className={clsx(styles.cardUser, size === "large" && styles.large)}
        >
          {photo.commentText && (
            <div
              className={clsx(
                styles.cardComment,
                size === "large" && styles.cardCommentLarge
              )}
            >
              <div className={showDesktop}>
                <LineClamp
                  text={photo.commentText}
                  line={size === "large" ? 4 : 2}
                />
              </div>
              <div className={showMobile}>
                <LineClamp text={photo.commentText} line={2} />
              </div>
            </div>
          )}

          {user !== undefined && (
            <UserIconName
              name={user.name}
              imageUrl={user.iconUrl}
              size={size === "large" ? "small" : "xsmall"}
              layout={user?.layout}
              style={user?.style}
              color={photo.commentText ? "base06Secondary" : "base06"}
              isSpaceBetween
            />
          )}
        </div>
      )}

      {/* TODO: tags */}
    </a>
  )
}
